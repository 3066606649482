import React from "react"
import Spacer from "../components/spacer"
import Layout from "../components/layout"
import { P, TextLink, TextBlockHeading, PageHeading } from '../components/typography'
import SEO from "../components/seo"

const PrivacyDisclosure = () => {
  const textData = [
    { header: 'Notice of Privacy Practices', 
      body: 
        <>
          <div>EFFECTIVE DATE: SEPTEMBER 2021</div>
          <div className="mb-3 mt-3"><i>This notice describes how medical information about you may be used and disclosed and how you can get access to this information. Please read it carefully.</i></div>
          <div className="font-bold mb-3 text-lg">We are required, by law to:</div>
          <P margin="mb-2 ml-8">
            1. Keep your health information private;
          </P>
          <P margin="mb-2 ml-8">
            2. Provide you with this Notice of Privacy Practices, which is also posted on our website (multnomahmedical.com)
          </P>
          <P margin="mb-2 ml-8">
            3. Act according to the terms of this notice; and
          </P>
          <P margin="mb-5 ml-8">
            4. Notify you in the event your protected health information is breached.
          </P>
          <div className="font-bold text-lg mb-3">We will not:</div>
          <P margin="mb-2 ml-8">
            1. Sell your protected health information (PHI), except as authorized by law, without your consent;
          </P>
          <P margin="mb-2 ml-8">
            2. Use your PHI to solicit you for fundraising activities;
          </P>
          <P margin="mb-5 ml-8">
            3. Use your PHI for any purposes related to marketing.
          </P>
          <div className="font-bold text-lg mb-3">We may, at any time, make changes to the terms of this notice. When we make changes we will:</div>
          <P margin="mb-2 ml-8">
            1. Apply the changes to all of the PHI we maintain;
          </P>
          <P margin="mb-2 ml-8">
            2. Update the notice posted in all of our facilities;
          </P>
          <P margin="mb-2 ml-8">
            3. Update the notice posted on our website; and
          </P>
          <P margin="mb-5 ml-8">
            4. Offer a copy of the updated notice at check-in during your next patient appointment.
          </P>
          <div className="font-bold text-2xl mb-3">How we may use or disclose your medical information.</div>
          <P margin="mb-2">
            <strong>For Treatment:</strong> We may use your medical information to provide you with medical treatment or services. For example we may disclose your medical information to doctors, nurses, technicians, health care students, or other care providers outside of Multnomah Medical Clinic who are involved with your care.
          </P>
          <P margin="mb-2">
            <strong>For Payment:</strong> We may use and disclose your medical information for the purposes of receiving payment on your behalf. For example, we may need to give your insurance company information about care you received so your insurance will pay for your care or we may need to notify your insurance company about a treatment you are about to receive in order to determine if your insurance will cover the treatment.
          </P>
          <P margin="mb-2">
            <strong>For Health Care Operations:</strong> We may use and disclose your medical information for our health care operations. For example, we may use your medical information to evaluate the quality and competence of our physicians, nurses and other health care workers or we may disclose your medical information to our internal administrators in order to resolve a complaint you may have filed.
          </P>
          <P margin="mb-2">
            <strong>Appointment Reminders:</strong> We may use and disclose medical information to contact you as a reminder that you have an appointment.
          </P>
          <P margin="mb-2">
            <strong>Treatment Alternatives:</strong> We may use and disclose medical information to tell you about or recommend possible treatment options or alternatives that may be of interest to you.
          </P>
          <P margin="mb-2">
            <strong>Health-Related Benefits and Services:</strong> We may use and disclose medical information to tell you about health-related benefits, services, or medical education classes that may be of interest to you.
          </P>
          <P margin="mb-2">
            <strong>Individuals Involved in Your Care or Payment for your Care:</strong> Unless you object, we may share or discuss information with your family, friends, or others involved in your care or payment for your care. Your health care provider can only discuss the information that the person involved needs to know about your care or payment for your care. If you object to this kind of disclosure please let your provider know.
          </P>
          <P margin="mb-2">
            <strong>Research:</strong> Under certain circumstances, we may use and disclose your medical information for research purposes. For example, a research study may be conducted to determine the recovery rates of patients who receive physical therapy verses those who do not receive physical therapy.
          </P>
          <P margin="mb-2">
            <strong>Limited Data Set Information:</strong> We may disclose limited medical information to third parties for purposes of research, public health and health care operations. This limited data set will not include any information which could be used to identify you directly.
          </P>
          <P margin="mb-2">
            <strong>As Required by Law:</strong> We will disclose your medical information when required to do so by federal, state or local law. For example, we are required to report child abuse, crimes committed with a deadly weapon, and animal bites to the appropriate state, county, or law enforcement authority.
          </P>
          <P margin="mb-2">
            <strong>Incidental disclosures:</strong> Certain incidental disclosures of your medical information occur as a byproduct of lawful and permitted use and disclosure of your medical information. For example, patients in a waiting room may overhear the name of another patient. We will take all reasonable precautions to protect your privacy.
          </P>
          <P margin="mb-2">
            <strong>Disclosures to Business Associates:</strong> In certain circumstances, we may need to share your medical information with a business associate (e.g., transcription company, computer system vendor, etc.) so it can perform a service on our behalf. We will have a written contract in place with the business associate requiring it to protect the privacy of your medical information.
          </P>
          <P margin="mb-2">
            <strong>Organ and Tissue Donation:</strong>  If you are an organ donor, we may disclose your medical information to organizations that handle organ procurement or organ, eye or tissue transplantation or to an organ donation bank, as necessary to facilitate organ or tissue donation and transplantation.
          </P>
          <P margin="mb-2">
            <strong>Military:</strong> If you are a member of the armed forces, we may disclose your medical information as required by military command authorities.
          </P>
          <P margin="mb-2">
            <strong>Workers’ Compensation:</strong> We may disclose medical information about you for workers’ compensation or similar programs, to the extent authorized by law. These programs provide benefits for work-related injuries or illness.
          </P>
          <P margin="mb-2">
            <strong>Serious and Imminent Threat to Health or Safety:</strong> We may use and disclose medical information about you when necessary to prevent a serious and imminent threat to your health or safety or the health or safety of the public.
          </P>
          <P margin="mb-2">
            <strong>Public Health Activities:</strong> We may disclose your medical information to public health agencies as required or authorized by state law to support public health activities. This generally includes, but is not limited to, the following:
          </P>
          <P margin="ml-8">
            1. to prevent or control disease, injury or disability;
          </P>
          <P margin="ml-8">
            2. to report births and deaths;
          </P>
          <P margin="ml-8">
            3. to report reactions to medications or problems with products and to enable product recalls, repairs or replacement;
          </P>
          <P margin="ml-8">
            4. to notify a person who may have been exposed to a disease or may be at risk for contracting or spreading a disease or condition; or
          </P>
          <P margin="ml-8 mb-2">
            5. to notify the appropriate government authority if we believe a patient has been the victim of abuse or neglect. We will only make this disclosure if the patient agrees or when required or authorized by law.
          </P>
          <P margin="mb-2">
            <strong>Disaster Relief Effort:</strong> Unless you object, we may disclose medical information about you to other health care providers and to an entity assisting in a disaster relief effort to coordinate care. We may share patient information as necessary to identify, locate and notify family members, guardians or anyone else responsible for your care, location, general condition or death.
          </P>
          <P margin="mb-2">
            <strong>Health Oversight Activities:</strong> We may disclose your medical information to a health oversight agency for activities authorized by law. For example, audits, investigations, inspections and licensure. These activities are necessary for the government to protect public health, monitor government programs, and comply with civil rights laws.
          </P>
          <P margin="mb-2">
            <strong>Lawful Subpoena or Court Order:</strong> If you are involved in a lawsuit or a dispute, we may disclose your medical information in response to a court or administrative order. We may also disclose your medical information in response to a subpoena, discovery request, or other lawful process by someone else involved in the dispute. In most circumstances, we will not disclose your medical information until efforts have been made to notify you of the request or to obtain an order protecting the information requested.
          </P>
          <P margin="mb-2">
            <strong>Law Enforcement:</strong> We may disclose your medical information if asked to do so by a law enforcement official or otherwise designated individual, including (but not limited to) the following:
          </P>
          <P margin="ml-8">
            1. in response to a court order, criminal subpoena, warrant or other lawful process;
          </P>
          <P margin="ml-8">
            2. limited information for the purpose of identifying or locating a suspect, fugitive, material witness or missing person;
          </P>
          <P margin="ml-8">
            3. about the victim of a crime, under certain limited circumstances;
          </P>
          <P margin="ml-8">
            4. about a death we believe may be the result of criminal conduct;
          </P>
          <P margin="ml-8">
            5. about criminal conduct at the facilities; and
          </P>
          <P margin="mb-2 ml-8">
            6. in emergency circumstances to report a crime; the location of the crime or crime victim; or the identity, description or location of the person who committed the crime to the extent the law requires.
          </P>
          <P margin="mb-2">
            <strong>Coroners, Medical Examiners and Funeral Directors:</strong> We may disclose medical information to a coroner, medical examiner or funeral director to identify a deceased person or determine the cause of death. We may also disclose medical information to funeral directors as necessary to carry out their duties.
          </P>
          <P margin="mb-2">
            <strong>National Security and Intelligence Activities:</strong> We may disclose your medical information to authorized federal officials for intelligence, counterintelligence, and other national security activities authorized by law.
          </P>
          <P margin="mb-2">
            <strong>Protective Services for the President and Others:</strong> We may disclose your medical information to authorized federal officials so they may provide protection to the President, other authorized persons or for foreign heads of state or conduct special investigations.
          </P>
          <P margin="mb-2">
            <strong>Inmate:</strong> If you are an inmate of a correctional institution or under the custody of a law enforcement official, we may disclose your medical information to the correctional institution or law enforcement official.
          </P>
          <P margin="mb-2">
            <strong>Other Uses of Medical Information:</strong> All other uses and disclosures of medical information not covered by this notice or the laws that apply to us will be made only with your written authorization. If you provide us authorization to use or disclose your medical information, you may revoke that authorization, in writing, at any time. If you revoke your authorization, we will no longer use or disclose your medical information for the purposes identified in your written revocation, unless we have already acted in reliance on your authorization.
          </P>
          <div className="font-bold text-2xl mb-3">You have rights with regards to how we use your medical information</div>
          <P margin="mb-2">
            <strong>Right to Request Limits on Uses or Disclosures of PHI:</strong> You have the right to ask that we limit how your information is used or disclosed. You must make the request in writing and tell us what information you want to limit and to whom you want the limits to apply. In most cases we are not required to agree to a restriction. Upon your written request we are required to restrict disclosure of your information to a payer (e.g. insurance company) if you make full payment for the service at the time of service. If we agree to a restriction you can ask us verbally to remove the restriction at any time.
          </P>
          <P margin="mb-2">
            <strong>Right to Choose How We Communicate with You:</strong> We will agree to reasonable requests. To carry out the request, we may also ask you for another address or another way to contact you, for example, mailing to a post office box. Requests must be made in writing to Multnomah Medical Clinic (see contact information below).
          </P>
          <P margin="mb-2">
            <strong>Right to See and Get Copies of Your Records:</strong> In most cases, you have the right to review or get copies of your records. You must make the request in writing and you may be charged a fee for the cost of copying your records. For more information please call 503-766-4338.
          </P>
          <P margin="mb-2">
            <strong>Right to Request a Correction or Update of Your Records:</strong> You may ask us to change (amend) information in your records if you think there is a mistake. In certain cases, we may deny your request for a change. If we deny your request, you have the right to file a statement with Multnomah Medical Clinic, stating that you disagree. We may prepare a response to your statement and will provide you with a copy of this response. If you wish to change information in your records please call 503-766-4338.
          </P>
          <P margin="mb-2">
            <strong>Right to Get a List of Disclosures:</strong> You may ask us for an accounting of disclosures we made of your medical information to the extent the law requires. To request an accounting of disclosures please call 503-766-4338.
          </P>
          <P margin="mb-2">
            <strong>Right to Get a Paper Copy of this Notice:</strong> You may ask us for a paper copy of this notice at any time.
          </P>
          <P margin="mb-4">
            <strong>Right to Revoke Permission:</strong> If you are asked to sign an authorization to use or disclose information, you can cancel that authorization at any time. You must make the request in writing. This will not affect information that has already been shared.
          </P>
          <div className="mb-3 mt-3"><i>Questions about what you've read?</i></div>
          <div className="font-bold text-lg">Multnomah Medical Clinic</div>
          <div className=" text-lg">9200 SE 91st Ave Suite 300</div>
          <div className=" text-lg">Happy Valley, OR 97086</div>
          <div className=" text-lg">503-766-4338</div>
          <div className=" text-lg mb-3">admin@multnomahmedical.com</div>
          <P margin="mb-4">
          You can also contact The U.S. Department of Health and Human Services, Office for Civil Rights at 206-615-2290 if you want to file a complaint or to report a problem with how we have used or disclosed information about you.
          </P>
          <P margin="mb-4">
          We will not retaliate against you for filing a complaint.
          </P>

        </>
    },
  ]

  return (
    <Layout>
          <SEO 
            title="Treatment Services We Offer" 
          />
          <div className="flex justify-center">
            <div className="text-center">
              {textData.map((textEntry, index) => ( 
                <div className={`px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-4xl ${index !== 0 ? 'pt-12' : ''}`}>
                  {index === 0 ? 
                    <PageHeading>{textEntry.header}</PageHeading> :
                    <TextBlockHeading wide>{textEntry.header}</TextBlockHeading>
                  }
                    <Spacer />
                  <div className="px-2 text-left">
                    {textEntry.body}
                  </div>
                </div>
              ))}
            </div>
          </div>
    </Layout>
  )
}

export default PrivacyDisclosure
